// const fetch = require("node-fetch");
const cookieName = {
  user: "userName",
  teacher: "teacherName",
};

const errorCodes = {
  invalidName: "INVALID_NAME",
  invalidTeacher: "INVALID_TEACHER",
};

const errorMap = {
  [errorCodes.invalidName]: "Please enter your first and last name.",
  [errorCodes.invalidTeacher]: "Please select your teacher's name.",
};

const noneTeacher = "none";
const teachers = {
  [noneTeacher]: "Select Your Teacher",
  amanda: "Amanda",
  steveAndEvanor: "Evenor & Steve",
  janeAndSam: "Jane & Sam",
  karen: "Jazmin & Karen",
  johnAndNatalie: "John & Natalie",
  kevin: "Kevin",
  vivanAndMaria: "Maria & Vivan",
};

class App {
  elements;
  cookieData;
  clearCache;
  weekendCode;
  areCookiesEnabled;
  deviceId;
  constructor({
    elements,
    cookieData,
    clearCache,
    weekendCode,
    areCookiesEnabled,
    deviceId,
  }) {
    this.elements = elements;
    this.cookieData = cookieData;
    this.clearCache = clearCache;
    this.weekendCode = weekendCode;
    this.areCookiesEnabled = areCookiesEnabled;
    this.deviceId = deviceId;
  }

  handleSubmit() {
    console.log({
      userName: this.elements.nameInput.value,
      teacherName: this.elements.teacherInput.value,
      weekendCode: this.weekendCode,
      deviceId: this.deviceId,
      usingCookieData: !!this.cookieData.userName,
    });
    if (!this.elements.nameInput.value) {
      return handleError(errorCodes.invalidName);
    }
    if (this.elements.teacherInput.value === noneTeacher) {
      return handleError(errorCodes.invalidTeacher);
    }

    let submitCheckin = true;
    if (!this.cookieData.userName || !this.cookieData.teacherName) {
      if (
        confirmSubmission(
          this.elements.nameInput.value,
          this.elements.teacherInput.value
        )
      ) {
        if (!this.cookieData.userName) {
          saveToCookie(cookieName.user, this.elements.nameInput.value);
        }
        if (!this.cookieData.teacherName) {
          saveToCookie(cookieName.teacher, this.elements.teacherInput.value);
        }
      } else {
        submitCheckin = false;
      }
    }
    if (submitCheckin) {
      this.elements.spinner.display = "initial";
      this.elements.checkinForm.disabled = true;
      checkinWithServer({
        userName: this.elements.nameInput.value,
        teacher: this.elements.teacherInput.value,
        weekendCode: this.weekendCode,
        deviceId: this.deviceId,
        usingCookieData: !!this.cookieData.userName,
      })
        .then((data) => {
          console.log(data);
          this.elements.successMessage.style.display = "initial";
          this.elements.checkinForm.style.display = "none";
          if (data.checkinType === "CHECK-OUT") {
            this.elements.checkOutMessage.style.display = "initial";
          } else {
            this.elements.checkInMessage.style.display = "initial";
          }
        })
        .catch((error) => {
          console.log("ERROR");
          console.log(error);
          this.elements.errorLog.innerText = (error.errors || []).join("\n");
          this.elements.errorLog.style.display = "initial";
          this.elements.checkinForm.disabled = false;
        })
        .finally(() => {
          this.elements.spinner.style.display = "none";
        });
    }
  }

  handleDocLoad() {
    populateTeacherSelect();
    this.elements.nameInput.focus();

    if (!this.areCookiesEnabled) {
      this.elements.checkinForm.style.display = "none";
      this.elements.errorLog.innerText =
        "Please use a browser that allows cookies.";
      this.elements.errorLog.style.display = "initial";
    } else if (this.clearCache) {
      expireAllCookies();
      clearUrl();
    } else {
      if (!!this.cookieData.userName) {
        this.elements.nameInput.value = this.cookieData.userName;
        this.elements.nameInput.disabled = true;
        this.elements.submitInput.focus();
      }
      if (!!this.cookieData.teacherName) {
        this.elements.teacherInput.value = this.cookieData.teacherName;
        this.elements.teacherInput.disabled = true;
      }
    }
    if (!this.elements.nameInput.value && !!navigator.virtualKeyboard) {
      navigator.virtualKeyboard.show();
    }
  }
}

function confirmSubmission(userName, teacherName) {
  console.log(userName);
  return confirm(
    `Sign in for:\n\n${userName}\n\nThis will tie your attendance to ${teachers[teacherName]}'s class?`
  );
}

function handleError(code) {
  alert(errorMap[code]);
  return false;
}

function getFromCookies(cookieKey) {
  const cookie = document.cookie;
  if (cookie == "") return null;
  return cookie
    .split("; ")
    .find((row) => row.startsWith(cookieKey))
    .split("=")[1];
}

function saveToCookie(key, value) {
  const cookieSettings =
    "path=/; SameSite=None; expires=Fri, 31 Dec 9999 23:59:59 GMT";
  document.cookie = `${key}=${value}; ${cookieSettings}`;
}

function expireAllCookies() {
  var Cookies = document.cookie.split(";");
  // set past expiry to all cookies
  const now = new Date(0).toUTCString();
  for (var i = 0; i < Cookies.length; i++) {
    document.cookie = Cookies[i] + "=; expires=" + now;
  }
}

function clearUrl() {
  window.location.replace(
    location.protocol + "//" + location.host + location.pathname
  );
}

function populateTeacherSelect() {
  const select = document.getElementById("teacher-select");

  // Clear existing options (if any)
  select.innerHTML = "";

  for (let key in teachers) {
    if (teachers.hasOwnProperty(key)) {
      const option = document.createElement("option");
      option.value = key;
      option.textContent = teachers[key];
      select.appendChild(option);
    }
  }
}

function checkinWithServer(data) {
  return new Promise((resolve, reject) => {
    fetch("https://conf-backend.kmhoran.org/checkin", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    }).then((response) => {
      if (response.ok) {
        response.json().then((data) => resolve(data));
      } else {
        response.json().then((data) => reject(data));
      }
    });
  });
}

const app = new App({
  elements: {
    checkinForm: document.getElementById("checkin-form"),
    nameInput: document.getElementById("name"),
    teacherInput: document.getElementById("teacher-select"),
    submitInput: document.getElementById("submit-btn"),
    successMessage: document.getElementById("success-message"),
    errorLog: document.getElementById("error-log"),
    spinner: document.getElementById("spinner"),
    checkInMessage: document.getElementById("check-in-message"),
    checkOutMessage: document.getElementById("check-out-message"),
  },
  cookieData: {
    userName: getFromCookies(cookieName.user),
    teacherName: getFromCookies(cookieName.teacher),
  },
  clearCache: new URLSearchParams(window.location.search).get("clear") != null,
  weekendCode: new URLSearchParams(window.location.search).get("code"),
  areCookiesEnabled: navigator.cookieEnabled,
  deviceId: new DeviceUUID().get(),
});

document.addEventListener("DOMContentLoaded", () => {
  app.handleDocLoad();
  document
    .getElementById("checkin-form")
    .addEventListener("submit", (event) => {
      event.preventDefault();
      app.handleSubmit();
    });
});
